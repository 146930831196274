//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Filters from './P2PPaymentMethodsAndCurrenciesPaymentMethodsFilters.vue';

import tableConfig from './tableConfig';
import actionsConfig from './actionsConfig';
import constant from '~/const';

export default {
  components: { Filters },

  data() {
    return {
      tableConfig,
      actionsConfig,
      selection: [],
      loader: false,
      countriesEnabled: false,
      requesitesEnabled: false,
      constant,
      paymentMethodCountries: [],
      paymentMethodData: {
        type: 0,
        regex: '',
        required: false,
        target: '',
        name: '',
        filedName: '',
      },
      modalPaymentMethod: {
        show: false,
        name: '',
        isEditing: false,
        loading: false,
        model: {
          id: 0,
          online: true,
          paymentMethodCategory: '',
          name: '',
          aliases: '',
          enabledP2P: true,
          enabledExch: false,
          sortId: 0,
        },
        icon: null,
      },
      modalManageCountries: {
        show: false,
        id: 0,
        name: '',
        loading: false,
        countryId: 0,
        data: [],
      },
      modalManageFields: {
        show: false,
        id: 0,
        name: '',
        loading: false,
        data: [],
        template: undefined,
      },
      modalManageFieldsTemplates: [
        {
          name: 'Credit card',
          fields: [
            {
              name: '',
              uId: '',
              fieldName: 'cardNumber',
              target: '',
              type: constant.p2p.PAYMENT_FIELD_TYPE.INTEGER,
              required: true,
              // eslint-disable-next-line
              regex: '/^\d{16,18}/',
            },
            {
              name: '',
              uId: '',
              fieldName: 'cardOwner',
              target: '',
              type: constant.p2p.PAYMENT_FIELD_TYPE.TEXT,
              required: true,
              // eslint-disable-next-line
              regex: '/^[A-Za-z]* [A-Za-z]*/',
            },
            {
              name: '',
              uId: '',
              fieldName: 'validThru',
              target: '',
              type: constant.p2p.PAYMENT_FIELD_TYPE.TEXT,
              required: true,
              // eslint-disable-next-line
              regex: '/^\d{2}\/\d{2}/',
            },
            {
              name: '',
              uId: '',
              fieldName: 'CVV',
              target: '',
              type: constant.p2p.PAYMENT_FIELD_TYPE.INTEGER,
              required: true,
              // eslint-disable-next-line
              regex: '/^\d{3}/',
            },
          ],
        },
        {
          name: 'Internet wallets',
          fields: [
            {
              name: '',
              uId: '',
              fieldName: 'number',
              target: '',
              type: constant.p2p.PAYMENT_FIELD_TYPE.TEXT,
              required: true,
              regex: '',
            },
          ],
        },
        {
          name: 'SBPay',
          fields: [
            {
              name: '',
              uId: '',
              fieldName: 'phoneNumber',
              target: '',
              type: constant.p2p.PAYMENT_FIELD_TYPE.TEXT,
              required: true,
              regex: '',
            },
          ],
        },
        {
          name: 'Bank transfer',
          fields: [
            {
              name: '',
              uId: '',
              fieldName: 'nameOrOrganization',
              target: '',
              type: constant.p2p.PAYMENT_FIELD_TYPE.TEXT,
              required: true,
              regex: '',
            },
            {
              name: '',
              uId: '',
              fieldName: 'accountNumberOrIBAN',
              target: '',
              type: constant.p2p.PAYMENT_FIELD_TYPE.TEXT,
              required: true,
              regex: '',
            },
            {
              name: '',
              uId: '',
              fieldName: 'swiftCodeOrNameOrCorrespondentAccount',
              target: '',
              type: constant.p2p.PAYMENT_FIELD_TYPE.TEXT,
              required: true,
              regex: '',
            },
          ],
        },
      ],
    };
  },

  // SINGLE TABLE COMPONENT
  // async mounted() {
  //   const { page } = this.$route.query;
  //   const numberPage = Number(page);
  //   if (numberPage) {
  //     this.setPageAction(numberPage);
  //   }

  //   this.setGeneralLoader(true);
  //   await this.loadData();
  //   this.setGeneralLoader(false);
  // },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('paymentMethodsAndCurrenciesPaymentMethods', ['page', 'pages', 'data', 'sort', 'countries']),

    aliasesModel: {
      get() {
        const { aliases } = this.modalPaymentMethod.model;
        try {
          // const aliasesArr = JSON.parse(aliases);
          // return aliasesArr.join(' / ');
          return aliases.join(' / ');
        } catch (error) {
          return '';
        }
      },
      set(value) {
        if (value.trim()) {
          const aliasesArr = value
            .split('/')
            .map((e) => e.trim());
          this.modalPaymentMethod.model.aliases = aliasesArr;
        } else {
          this.modalPaymentMethod.model.aliases = null;
        }
      },
    },

    isApplyDisabled() {
      const {
        paymentMethodCategory,
        name,
      } = this.modalPaymentMethod.model;
      return !paymentMethodCategory === null || !name;
    },

    countriesSwitchTitle() {
      return this.countriesEnabled ? this.$t('Choose available countries') : this.$t('By default all countries are available');
    },

    countriesToAdd() {
      return this.countries.filter((e) => !this.modalManageCountries.data.map((c) => c.country.id).includes(e.id));
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('paymentMethodsAndCurrenciesPaymentMethods', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      editPaymentMethodAction: 'editPaymentMethod',
      createPaymentMethodAction: 'createPaymentMethod',
      deletePaymentMethodAction: 'deletePaymentMethod',
      setP2PIconAction: 'setP2PIcon',
      getCountryPaymentMethodsAction: 'getCountryPaymentMethods',
      createCountryPaymentMethodAction: 'createCountryPaymentMethod',
      deleteCountryPaymentMethodAction: 'deleteCountryPaymentMethod',
      createPaymentMethodFieldAction: 'createPaymentMethodField',
      editPaymentMethodFieldAction: 'editPaymentMethodField',
      deletePaymentMethodFieldAction: 'deletePaymentMethodField',
      getPaymentMethodsAction: 'getPaymentMethods',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      // SINGLE TABLE COMPONENT
      // const query = { page: value };
      // this.$router.replace({ query });

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    edit(row) {
      this.modalPaymentMethod.show = true;
      this.modalPaymentMethod.isEditing = true;
      this.modalPaymentMethod.name = row.name;
      this.modalPaymentMethod.icon = null;
      this.modalPaymentMethod.model = {
        id: row.id,
        online: row.online,
        paymentMethodCategory: row.paymentMethodCategory,
        name: row.name,
        aliases: row.aliases,
        enabledP2P: row.enabledP2P,
        enabledExch: row.enabledExch,
        sortId: row.sortId,
      };
    },

    async applyEdit() {
      this.modalPaymentMethod.loading = true;
      try {
        const { model, icon } = this.modalPaymentMethod;
        // model.aliases = JSON.parse(model.aliases);
        await this.editPaymentMethodAction({
          ...model,
          id: undefined,
          paymentMethodId: model.id,
        });
        if (icon) {
          await this.setP2PIcon(model.id);
        }
        this.loadData();
        this.modalPaymentMethod.show = false;
        this.setSuccessNotification('Payment method successfully changed');
      } catch (error) {
        this.setErrorNotification(error.message || error.Message);
      }
      this.modalPaymentMethod.loading = false;
    },

    create() {
      this.modalPaymentMethod.show = true;
      this.modalPaymentMethod.isEditing = false;
      this.modalPaymentMethod.name = '';
      this.modalPaymentMethod.icon = null;
      this.modalPaymentMethod.model = {
        // id: null,
        online: true,
        paymentMethodCategory: null,
        name: '',
        aliases: null,
        enabledP2P: true,
        enabledExch: false,
        sortId: 0,
      };
    },

    async applyCreate() {
      this.modalPaymentMethod.loading = true;
      try {
        const { model, icon } = this.modalPaymentMethod;
        const { id } = await this.createPaymentMethodAction(model);
        if (icon) {
          await this.setP2PIcon(id);
        }
        this.loadData();
        this.modalPaymentMethod.show = false;
        this.setSuccessNotification('Payment method successfully created');
      } catch (error) {
        this.setErrorNotification(error.message ?? error.Message);
      }
      this.modalPaymentMethod.loading = false;
    },

    async setP2PIcon(id) {
      const { icon } = this.modalPaymentMethod;
      const formData = new FormData();
      formData.append('Icon', icon, icon.name);
      await this.setP2PIconAction({
        params: {
          paymentMethodId: id,
        },
        data: formData,
      });
    },

    del(id) {
      this.confirmAction({
        title: 'Are you sure you want to delete this payment method?',
        callback: async () => {
          this.setGeneralProgress(true);
          await this.deletePaymentMethodAction(id);
          this.setGeneralProgress(false);
          this.loadData();
          this.setSuccessNotification('Payment method successfully deleted');
        },
      });
    },

    getIcon(row) {
      const icon = row.icon ?? (row.icons?.[0]);
      if (!icon) return null;

      return icon.includes('://') ? icon : `${constant.settings.EXCHANGE_URL}${icon}`;
    },

    async manageCountries(row) {
      this.setGeneralProgress(true);
      const data = await this.getCountryPaymentMethodsAction({
        paymentMethodsIdList: [row.id],
        currenciesIdList: [0],
      });
      this.modalManageCountries.data = data.items ?? [];
      this.setGeneralProgress(false);
      this.modalManageCountries.id = row.id;
      this.modalManageCountries.name = row.name;
      this.modalManageCountries.countryId = 0;
      this.modalManageCountries.show = true;
    },

    async addCountry() {
      this.setGeneralProgress(true);
      this.modalManageCountries.loading = true;
      await this.createCountryPaymentMethodAction({
        paymentMethodId: this.modalManageCountries.id,
        countryId: this.modalManageCountries.countryId,
        currencyId: 0,
      });
      const data = await this.getCountryPaymentMethodsAction({
        paymentMethodsIdList: [this.modalManageCountries.id],
        currenciesIdList: [0],
      });
      this.modalManageCountries.data = data.items ?? [];
      this.modalManageCountries.countryId = 0;
      this.modalManageCountries.loading = false;
      this.setGeneralProgress(false);
    },

    async removeCountry(id) {
      this.setGeneralProgress(true);
      this.modalManageCountries.loading = true;
      await this.deleteCountryPaymentMethodAction({
        countryPaymentMethodId: id,
      });
      const data = await this.getCountryPaymentMethodsAction({
        paymentMethodsIdList: [this.modalManageCountries.id],
        currenciesIdList: [0],
      });
      this.modalManageCountries.data = data.items ?? [];
      this.modalManageCountries.countryId = 0;
      this.modalManageCountries.loading = false;
      this.setGeneralProgress(false);
    },

    manageFields(row) {
      this.modalManageFields.id = row.id;
      this.modalManageFields.name = row.name;
      this.modalManageFields.data = row.fields;
      this.modalManageFields.show = true;
    },

    async removeField(id) {
      this.setGeneralProgress(true);
      this.modalManageFields.loading = true;
      await this.deletePaymentMethodFieldAction({ paymentMethodFieldId: id });
      const updatedPaymentMethod = await this.getPaymentMethodsAction({ idList: [this.modalManageFields.id] });
      this.modalManageFields.data = updatedPaymentMethod.items[0].fields;
      this.modalManageFields.loading = false;
      this.setGeneralProgress(false);
      this.loadData();
    },

    async applyField({ id, ...item }) {
      this.setGeneralProgress(true);
      this.modalManageFields.loading = true;
      if (id) {
        await this.editPaymentMethodFieldAction({
          ...item,
          paymentMethodFieldId: id,
        });
      } else {
        await this.createPaymentMethodFieldAction({
          ...item,
          paymentMethodId: this.modalManageFields.id,
        });
      }
      const updatedPaymentMethod = await this.getPaymentMethodsAction({ idList: [this.modalManageFields.id] });
      this.modalManageFields.data = updatedPaymentMethod.items[0].fields;
      this.modalManageFields.loading = false;
      this.setGeneralProgress(false);
      this.loadData();
    },

    addField() {
      this.modalManageFields.data.push({
        name: '',
        uId: '',
        fieldName: '',
        target: '',
        type: constant.p2p.PAYMENT_FIELD_TYPE.BOOLEAN,
        required: false,
        regex: '',
      });
    },

    applyFieldTemplate() {
      this.modalManageFields.data = this.modalManageFields.template;
    },

    async applyAllFields() {
      this.setGeneralProgress(true);
      this.modalManageFields.loading = true;

      await Promise.allSettled(this.modalManageFields.data.map(({ id, ...item }) => {
        return ((async () => {
          if (id) {
            await this.editPaymentMethodFieldAction({
              ...item,
              paymentMethodFieldId: id,
            });
          } else {
            await this.createPaymentMethodFieldAction({
              ...item,
              paymentMethodId: this.modalManageFields.id,
            });
          }
        })());
      }));

      const updatedPaymentMethod = await this.getPaymentMethodsAction({ idList: [this.modalManageFields.id] });
      this.modalManageFields.data = updatedPaymentMethod.items[0].fields;
      this.modalManageFields.loading = false;
      this.setGeneralProgress(false);
      this.loadData();
    },
  },
};
