var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section"},[_c('div',{staticClass:"pa-4 pb-0"},[_c('v-btn',{attrs:{"color":"success","outlined":"","height":"40px"},on:{"click":_vm.createMethod}},[_vm._v(" "+_vm._s(_vm.$t('Add payment method'))+" ")])],1),_c('div',{staticClass:"pa-4 section__table"},[_c('UiTable',{attrs:{"selection":_vm.selection,"data":_vm.preparedData,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"533px","show-select":false,"show-actions":false},on:{"update:selection":function($event){_vm.selection=$event},"sort":_vm.applySort},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('DropdownList',{attrs:{"config":_vm.actionsConfig},on:{"action":_vm.actionHandler}})]},proxy:true},{key:"methodHeader",fn:function(){return [_c('v-text-field',{attrs:{"dense":"","outlined":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"method",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"mr-3"},[(row.icon)?_c('img',{staticClass:"d-flex section__icon",staticStyle:{"width":"32px"},attrs:{"src":row.icon.includes('://') ? row.icon : ("" + (_vm.constant.settings.EXCHANGE_URL) + (row.icon))}}):_vm._e()]),_c('div',[_c('div',[_vm._v(" "+_vm._s(row.name)+" ")])])])]}},{key:"type",fn:function(ref){
var row = ref.row;
return [_c('div',{staticStyle:{"line-height":"1.33"}},[_c('div',[_vm._v(" "+_vm._s(_vm.$utils.getCaption( _vm.constant.operations.TRANSACTION_METHOD_TYPE_LIST, row.transactionMethodType ))+" ")]),_c('div',{staticClass:"overline",class:{
              'error--text': row.transactionType ===
                _vm.constant.operations.TRANSACTION_TYPE.WITHDRAWAL,
              'success--text': row.transactionType ===
                _vm.constant.operations.TRANSACTION_TYPE.DEPOSIT,
            },staticStyle:{"line-height":"1.33"}},[_vm._v(" "+_vm._s(_vm.$utils.getCaption( _vm.constant.operations.TRANSACTION_TYPE_LIST, row.transactionType ))+" ")])])]}},{key:"decimals",fn:function(ref){
            var row = ref.row;
return [_c('div',{staticStyle:{"line-height":"1.33"}},[(!row.decimalsEnabled)?_c('div',[_vm._v(" "+_vm._s(_vm.getCurrencyDecimalsById(row.currencyId))+" ")]):_c('div',[_c('div',{staticClass:"text--disabled"},[_vm._v(" "+_vm._s(_vm.$t('General:'))+" "+_vm._s(_vm.getCurrencyDecimalsById(row.currencyId))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.$t('Custom:'))+" "+_vm._s(row.decimals)+" ")])])])]}},{key:"limits",fn:function(ref){
            var row = ref.row;
return [(_vm.checkIsLargeFloat(row.minAmount))?[(!row.decimalsEnabled)?_c('div',[_vm._v(" "+_vm._s(row.minAmount.toFixed(_vm.getCurrencyDecimalsById(row.currencyId)).toString())+" – ")]):_vm._e(),_c('div',[_vm._v(" "+_vm._s(row.minAmount.toFixed(row.decimals).toString())+" ")])]:[_vm._v(" "+_vm._s(row.minAmount)+" – "+_vm._s(row.maxAmount)+" ")]]}},{key:"fee",fn:function(ref){
            var row = ref.row;
return [_c('div',{staticStyle:{"line-height":"1.33"}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('Absolute:'))+" "+_vm._s(row.absFee)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.$t('Percent:'))+" "+_vm._s(row.percFee)+"% ")])])]}},{key:"feeLimits",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(row.minFee)+" – "+_vm._s(row.maxFee)+" ")]}},{key:"cryptoCurrency",fn:function(ref){
            var row = ref.row;
return [(row.cryptoCurrencyId)?_c('span',[_vm._v(" "+_vm._s(_vm.getCryptoCurrencyTitleById(row.cryptoCurrencyId))+" ")]):_vm._e()]}},{key:"cryptoToken",fn:function(ref){
            var row = ref.row;
return [(row.cryptoTokenId)?_c('span',[_vm._v(" "+_vm._s(_vm.getCryptoTokenTitleById(row.cryptoTokenId))+" ")]):_vm._e()]}},{key:"status",fn:function(ref){
            var row = ref.row;
return [_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',[(row.enabled)?_c('span',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.$t('Enabled'))+" ")]):_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.$t('Disabled'))+" ")])]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.editMethod(row)}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)],1)]}}])})],1),_c('Modal',{attrs:{"data":_vm.editingMethod.data,"is-creating":_vm.editingMethod.isCreating},on:{"apply":_vm.applyEdit,"remove":_vm.applyRemove},model:{value:(_vm.editingMethod.modal),callback:function ($$v) {_vm.$set(_vm.editingMethod, "modal", $$v)},expression:"editingMethod.modal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }