//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import PaymentsAndCurrenciesPayments from '~/views/PaymentsAndCurrenciesSystem/PaymentsAndCurrenciesPayments/PaymentsAndCurrenciesPayments.vue';
import PaymentsAndCurrenciesP2PPayments from '~/views/PaymentsAndCurrenciesSystem/PaymentsAndCurrenciesP2PPayments/PaymentsAndCurrenciesP2PPayments.vue';

export default {
  components: {
    PaymentsAndCurrenciesPayments,
    PaymentsAndCurrenciesP2PPayments,
  },

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.loadPayments(),
      this.loadCryptoCurrencies(),
      this.loadCryptoTokens(),
      this.loadPaymentMethods(),
      this.loadCountriesAction(),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader']),
    ...mapActions('paymentsAndCurrenciesPayments', {
      loadPayments: 'loadData',
      loadCryptoCurrencies: 'loadCryptoCurrencies',
      loadCryptoTokens: 'loadCryptoTokens',
    }),
    ...mapActions('paymentMethodsAndCurrenciesPaymentMethods', {
      loadPaymentMethods: 'loadData',
      loadCountriesAction: 'getCountries',
    }),
  },
};
