//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Extra from './Extra/Extra.vue';

import tableConfig from './tableConfig';
import constant from '~/const';

export default {
  components: { Extra },

  data() {
    return {
      tableConfig,
      loader: false,
      currentCurrencyId: 0,
      search: '',
      constant,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('paymentsAndCurrenciesPayments', ['page', 'pages', 'data', 'sort']),

    preparedData() {
      const { data } = this;
      const search = this.search.toLowerCase();
      const filteredData = data.filter((e) => (
        e.name.toLowerCase().includes(search) || e.title.toLowerCase().includes(search)
      ));
      const extendedData = [
        {
          id: 0,
          title: 'All currencies',
        },
        ...filteredData,
      ];
      return extendedData;
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
    ]),
    ...mapActions('paymentsAndCurrenciesPayments', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    setCurrentCurrency({ id }) {
      this.currentCurrencyId = id;
    },
  },
};
